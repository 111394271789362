<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="editedItem.ref"
                    :readonly="readonly"
                    label="Réference"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="levels"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.levels"
                    :readonly="readonly || editedItem.id > 0"
                    :label="range_name"
                    :rules="[
                      (v) => !!v || range_name + ' obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                    ]"
                    hide-spin-buttons
                    @input="levels_change"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    type="text"
                    dense
                    v-model="editedItem.gps"
                    :readonly="readonly"
                    label="GPS"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.id < 0 && editedItem.levels > 1"
                >
                  <v-text-field
                    autocomplete="off"
                    prepend-inner-icon="mdi-arrow-split-horizontal"
                    :ref="'nombre'"
                    dense
                    v-model="editedItem.nombre"
                    :label="'Nombre'"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    @input="nombre_change"
                    solo-inverted
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="(level, index) in levels" :key="level.id" dense>
                <v-col
                  cols="12"
                  sm="3"
                  md="2"
                  class="mt-1"
                  v-if="editedItem.id < 0"
                >
                  <v-chip>
                    <v-avatar left class="green">
                      {{ index + 1 }}
                    </v-avatar>
                    {{ level.caption }}
                  </v-chip>
                </v-col>

                <v-col cols="12" sm="2" md="2" v-if="editedItem.id < 0">
                  <v-text-field
                    outlined
                    filled
                    autocomplete="off"
                    :ref="'level' + index"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="level.boxs"
                    :label="'Nombre de ' + (box_name ? box_name : ' boxs')"
                    :rules="[
                      (v) => !!v || 'Nombre obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                    ]"
                    :key="kq"
                    :readonly="readonly"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
//const cursorselect = () => import("./CursorSelect.vue");
import INSERT_RACK from "../graphql/Depot/INSERT_RACK.gql";
import UPDATE_RACK from "../graphql/Depot/UPDATE_RACK.gql";
export default {
  components: {},
  name: "rackform",
  props: {
    item: Object,
    items: Array,
    depot: Object,
    readonly: Boolean,
    showForm: Boolean,
    rack_name: String,
    range_name: String,
    box_name: String,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    levels: [
      {
        id: 0,
        caption: "Level N° 1",
        boxs: 1,
      },
    ],
    kq: 100,
    defaultItem: {
      id: -1,
      levels: 1,
    },
    editedItem: {},
  }),

  computed: {
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouv. " + this.rack_name
          : this.rack_name +
              "  " +
              (this.item.ref ? this.item.ref : this.item.code);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.levels[0].caption = this.range_name + " N° 1";
      }
    }
  },

  methods: {
    levels_change() {
      this.levels = [];
      for (let index = 0; index < this.editedItem.levels; index++) {
        this.levels.push({
          id: index,
          caption: this.range_name + " N° " + (index + 1),
          boxs: 1,
        });
      }
    },

    nombre_change() {
      this.levels.forEach((element) => {
        element.boxs = parseFloat(this.editedItem.nombre);
      });
      this.kq++;
    },
    close() {
      this.$emit("close", this.items);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    verifie_double() {
      let ok = true;
      let code = "";
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (this.editedItem.id > 0) {
          if (element.ref) {
            let l = this.items.filter((elm) => elm.id != element.id);
            for (let index = 0; index < l.length; index++) {
              const elm = l[index];
              if (elm.ref.toLowerCase() == element.ref.toLowerCase()) {
                code = element.ref;
                ok = false;
                break;
              }
            }
          }
        } else {
          if (this.editedItem.ref)
            if (
              this.editedItem.ref.toLowerCase() == element.ref.toLowerCase()
            ) {
              code = element.ref;
              ok = false;
            }
        }

        if (ok == false) break;
      }

      if (ok == false) {
        this.snackbar_text = "Erreur code " + code + " en double! verifier";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = this.verifie_double();
        let box_list = [];
        if (ok) {
          this.progress = true;
          if (this.editedItem.id > 0) {
            let v = {
              rack: {
                id: this.editedItem.id,
                ref: this.editedItem.ref,
                levels: this.editedItem.levels,
                gps: this.editedItem.gps,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_RACK, v);

            this.progress = false;
            this.items.splice(this.item.index, 1, this.editedItem);
            this.$emit("change", this.editedItem);
            this.close();
          } else {
            let v = {
              rack: [
                {
                  ref: this.editedItem.ref,
                  depot_id: this.depot.id,
                  levels: this.editedItem.levels,
                  gps: this.editedItem.gps,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              ],
              depot_id: this.depot.id,
              levels: this.levels,
            };
            let r = await this.maj(INSERT_RACK, v);
            if (r) {
              let d = JSON.parse(r.InsertRack);
              this.editedItem.id = d[0].id;
              this.editedItem.code = d[0].code;
              box_list = d[0].boxs;
              this.items.push(this.editedItem);

              this.progress = false;
              this.close();
            }
            this.$emit("add", this.editedItem, box_list);
          }
        }
      }
    },
  },
};
</script>
